export enum PriceFor {
  Kilogram = "килограмм",
  Litres = "литры",
  Pieces = "штуки",
  SquareMetre = "квадратный метр",
  Hour = "час",
}
export type Service = {
  _id: string;
  title: string;
  description: string;
  price: number;
  priceFor: PriceFor;
  owner: string;
  city: string;
  ownerPhoto: string;
  ownerName: string;
  category: number;
  createdAt: string;
};
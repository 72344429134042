import { useState } from "react";
import axios from "axios";
import { API } from "../config/config";

export const useCourier = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const deleteCourier = async (id: string) => {
        setLoading(true);
        setError(null);
        try {
            await axios.delete(`${API}/courier/delete-regular/${id}`);
        } catch (err: any) {
            setError(err.response.data.error);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const addCourier = async (phone: string) => {
        setLoading(true);
        setError(null);
        try {
            const formattedPhone = `+7${phone}`;
            await axios.post(`${API}/courier/add-regular/${formattedPhone}?userType=seller`);
        } catch (err: any) {
            setError(err.response.data.error);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        deleteCourier,
        addCourier,
        loading,
        error,
    };
};


import React, { useState } from "react";
import { Modal, Table, Input, Spin, Empty, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { Vacancy, VacancyRequest } from "../../types/vacancy.type";

interface VacancyRequestsModalProps {
  visible: boolean;
  onClose: () => void;
  vacancy: Vacancy | null;
  requests: VacancyRequest[];
  loading: boolean;
}

export default function VacancyRequestsModal({
  visible,
  onClose,
  vacancy,
  requests,
  loading,
}: VacancyRequestsModalProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const columns: ColumnsType<VacancyRequest> = [
    {
      title: "Имя",
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      title: "Телефон",
      dataIndex: "clientPhone",
      key: "clientPhone",
    },
    {
      title: "Дата заявки",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: VacancyRequest, b: VacancyRequest) =>
        dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      render: (date: string) => dayjs(date).format("DD.MM.YYYY"),
    },
  ];
  const filteredRequests = requests.filter((request) =>
    request.clientName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <Modal
      title="Заявки на вакансию"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Spin spinning={loading}>
        {vacancy && (
          <div style={{ marginBottom: 24 }}>
            <Typography.Title level={4}>{vacancy.title}</Typography.Title>
            <Typography.Paragraph>
              <strong>Ставка:</strong> {vacancy.salary}₽ (в {vacancy.salaryPer})
            </Typography.Paragraph>
            <Typography.Paragraph>
              <strong>График:</strong> {vacancy.schedule}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <strong>Требования:</strong> {vacancy.requirements}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <strong>Обязанности:</strong> {vacancy.responsibilities}
            </Typography.Paragraph>
          </div>
        )}
        <div style={{ marginBottom: 16, display: "flex", gap: "8px" }}>
          <Input
            placeholder="Поиск по имени"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            allowClear
          />
        </div>
        {filteredRequests.length > 0 ? (
          <Table
            dataSource={filteredRequests}
            columns={columns}
            rowKey="_id"
            pagination={false}
          />
        ) : (
          <Empty description="Нет заявок" />
        )}
      </Spin>
    </Modal>
  );
}

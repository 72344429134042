export enum Schedule {
  FullTime = "полный день",
  PartTime = "неполный день",
  Flexible = "гибкий график",
}

export enum SalaryPer {
  Hour = "час",
  Month = "месяц",
  Day = "день",
}

export interface Vacancy {
  _id: string;
  title: string;
  schedule: Schedule;
  salary: number;
  requirements: string;
  responsibilities: string;
  salaryPer: SalaryPer
  owner: string;
}
export interface VacancyRequest {
  _id: string;
  clientId: string;
  clientName: string;
  clientPhone: string;
  vacancyId: string;
  createdAt: string;
}
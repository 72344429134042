export enum CourierStatus {
    ACTIVE = "активен",
    INACTIVE = "неактивен",
}

export enum CourierType {
    PEDESTRIAN = "Пешеход",
    CYCLE_MOTO = "Велосипед/Мото",
    AUTO = "Авто",
    TRUCK = "Грузовик",
}

export type Courier = {
    _id: string
    name: string;
    surname: string;
    phone: string;
    type: CourierType;
    city: string;
    status: CourierStatus;
    photoUri: string;
    sellerId: string;
    sellerName: string;
};

import {
  Typography,
  Table,
  Tag,
  Drawer,
  Button,
  List,
  Divider,
  Row,
  Col,
  Statistic,
  Input,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import useGetRequest from "../../hooks/useGetRequest";
import { API } from "../../config/config";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import { Order, OrderProduct, OrderStatus } from "../../types/order.type";
import { getTagColor } from "../../utils/getTagColor";
import dayjs from "dayjs";
import { ColumnsType } from "antd/es/table";

const { Title, Paragraph } = Typography;
const { Search } = Input;

export default function Orders() {
  const { authState } = useAuth();
  const { data, loading } = useGetRequest(
    `${API}/seller/my-orders?userType=${authState?.userType}`
  );
  const [orders, setOrders] = useState<Order[]>([]);
  const [visible, setVisible] = useState(false);
  const [currentProducts, setCurrentProducts] = useState<OrderProduct[]>([]);
  const [currentOrder, setCurrentOrder] = useState<Order | null>(null);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (data) {
      setOrders(data.orders);
    }
  }, [data]);

  const showDrawer = (products: OrderProduct[], order: Order) => {
    setCurrentProducts(products);
    setCurrentOrder(order);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const getTotalPrice = (products: OrderProduct[]) => {
    return products.reduce(
      (sum, product) => sum + product.price * product.quantity,
      0
    );
  };

  const getTotalWeight = (products: OrderProduct[]) => {
    return products.reduce(
      (sum, product) => sum + product.weight * product.quantity,
      0
    );
  };

  const filteredOrders = orders.filter((order) =>
    order.trackingId.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      title: "ID отслеживания",
      dataIndex: "trackingId",
      key: "trackingId",
      width: 150,
      ellipsis: true,
      sorter: (a: Order, b: Order) => a.trackingId.localeCompare(b.trackingId),
    },
    {
      title: "Дата",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      width: 200,
      render: (text: Date) => dayjs(text).format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a: Order, b: Order) =>
        dayjs(a.deliveryDate).unix() - dayjs(b.deliveryDate).unix(),
    },
    {
      title: "Адрес клиента",
      key: "clientPoint",
      width: 250,
      render: (text: any, record: Order) => (
        <div
          style={{
            maxWidth: 250,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <p>
            {record.clientPoint.street}, {record.clientPoint.city}
          </p>
          <p>
            {record.clientPoint.apartment}, Этаж {record.clientPoint.floor}
          </p>
        </div>
      ),
    },
    {
      title: "Телефон курьера",
      dataIndex: "courierPhone",
      key: "courierPhone",
      width: 150,
      render: (text: any, record: Order) => record.courierPhone || "Нет данных",
    },
    {
      title: "Телефон клиента",
      dataIndex: "clientPhone",
      key: "clientPhone",
      width: 150,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (status: OrderStatus) => (
        <Tag color={getTagColor(status)}>{status}</Tag>
      ),
      filters: Object.values(OrderStatus).map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value: string | number | boolean, record: Order) =>
        record.status === (value as OrderStatus),
    },
    {
      title: "Общая цена",
      key: "totalPrice",
      width: 150,
      render: (text: any, record: Order) => (
        <span>
          {getTotalPrice(
            record.sellerProducts.flatMap((sp) => sp.products)
          ).toFixed(2)}{" "}
          ₽
        </span>
      ),
    },
    {
      title: "Заплатили за доставку",
      key: "deliveryAmount",
      width: 150,
      render: (text: any, record: Order) => {
        const totalDeliveryAmount = record.sellerProducts.reduce(
          (total, sellerProduct) => total + sellerProduct.deliveryAmount,
          0
        );
        return <span>{totalDeliveryAmount.toFixed(2)} ₽</span>;
      },
    },

    {
      title: "Общий вес",
      key: "totalWeight",
      width: 150,
      render: (text: any, record: Order) => (
        <span>
          {(
            getTotalWeight(record.sellerProducts.flatMap((sp) => sp.products)) /
            1000
          ).toFixed(2)}{" "}
          кг
        </span>
      ),
    },
    {
      title: "Действия",
      key: "actions",
      width: 150,
      render: (text: any, record: Order) => (
        <Button
          type="primary"
          onClick={() =>
            showDrawer(
              record.sellerProducts.flatMap((sp) => sp.products),
              record
            )
          }
        >
          Просмотреть товары
        </Button>
      ),
    },
  ] as ColumnsType<Order>;

  return (
    <div style={{ padding: "16px" }}>
      <Title level={2} style={{ marginBottom: 24 }}>
        Заказы
      </Title>
      <Space style={{ marginBottom: 24 }}>
        <Input
          allowClear
          placeholder="Поиск по ID отслеживания"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          style={{ width: 240 }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={filteredOrders}
        rowKey="trackingId"
        loading={loading}
        scroll={{ x: "max-content" }}
      />
      <Drawer
        width={720}
        onClose={onClose}
        open={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {currentOrder && (
          <>
            <Title level={4}>Детали заказа</Title>
            <Paragraph>
              <strong>ID отслеживания:</strong> {currentOrder.trackingId}
            </Paragraph>
            <Paragraph>
              <strong>Дата доставки:</strong>{" "}
              {dayjs(currentOrder.deliveryDate).format("YYYY-MM-DD HH:mm:ss")}
            </Paragraph>
            <Paragraph>
              <strong>Имя клиента:</strong> {currentOrder.clientName}
            </Paragraph>
            <Paragraph>
              <strong>Телефон клиента:</strong> {currentOrder.clientPhone}
            </Paragraph>
            <Paragraph>
              <strong>Имя курьера:</strong>{" "}
              {currentOrder.courierId || "Нет данных"}
            </Paragraph>
            <Paragraph>
              <strong>Телефон курьера:</strong>{" "}
              {currentOrder.courierPhone || "Нет данных"}
            </Paragraph>
            <Divider />
            <Title level={4}>Товары</Title>
            <List
              itemLayout="horizontal"
              dataSource={currentProducts}
              renderItem={(product) => (
                <List.Item>
                  <List.Item.Meta
                    title={product.title}
                    description={`Опция: ${product.selectedOption} | Цена: ${product.price} ₽ | Количество: ${product.quantity}`}
                  />
                </List.Item>
              )}
            />
            <Divider />
            <Row>
              <Col span={12}>
                <Statistic
                  title="Общая цена"
                  value={getTotalPrice(currentProducts).toFixed(2)}
                  suffix="₽"
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Общий вес"
                  value={(getTotalWeight(currentProducts) / 1000).toFixed(2)}
                  suffix="кг"
                />
              </Col>
            </Row>
          </>
        )}
      </Drawer>
    </div>
  );
}

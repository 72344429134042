import React, { useState, useEffect } from "react";
import { Modal, Form, Input, InputNumber, Button, Select } from "antd";
import { PriceFor, Service } from "../../types/service.type";
import { services } from "../../constants/services";

interface EditServiceProps {
  visible: boolean;
  onClose: () => void;
  service: Service;
  onUpdateService?: (serviceId: string, serviceData: Partial<Service>) => void;
}

const EditService: React.FC<EditServiceProps> = ({
  visible,
  onClose,
  service,
  onUpdateService,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(
    service.category
  );

  useEffect(() => {
    form.setFieldsValue({
      title: service.title,
      description: service.description,
      price: service.price,
      category: service.category,
      priceFor: service.priceFor,
    });
  }, [service, form]);

  const handleCategoryChange = (value: number) => {
    setSelectedCategory(value);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const serviceData = {
      title: values.title,
      description: values.description,
      price: values.price,
      category: selectedCategory,
      priceFor: values.priceFor,
    };

    try {
      if (onUpdateService) {
        await onUpdateService(service._id, serviceData);
      }
      form.resetFields();
      setSelectedCategory(undefined);
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Редактировать услугу"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="title"
          label="Название"
          rules={[
            { required: true, message: "Пожалуйста, введите название услуги!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Описание"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите описание услуги!",
            },
            {
              max: 400,
              message: "Описание не может содержать более 400 символов",
            },
            {
              min: 30,
              message: "Описание не может содержать менее 30 символов",
            },
          ]}
        >
          <Input.TextArea rows={4} minLength={30} maxLength={400} showCount />
        </Form.Item>
        <Form.Item
          name="price"
          label="Цена"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите стоимость услуги!",
            },
          ]}
        >
          <InputNumber min={0} suffix="₽" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="category"
          label="Категория"
          rules={[
            { required: true, message: "Пожалуйста, выберите категорию!" },
          ]}
        >
          <Select
            placeholder="Выберите категорию"
            onChange={handleCategoryChange}
            value={selectedCategory}
          >
            {services.map((service) => (
              <Select.Option key={service.id} value={service.id}>
                {service.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="priceFor"
          label="Цена за"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите, за что указывается цена!",
            },
          ]}
        >
          <Select placeholder="Выберите единицу измерения">
            {Object.values(PriceFor).map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            loading={loading}
            htmlType="submit"
            style={{ marginRight: "8px" }}
          >
            Сохранить
          </Button>
          <Button onClick={onClose}>Отмена</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditService;

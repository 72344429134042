import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, InputNumber, Button, Image, Select } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { WholesaleProduct } from "../../types/wholesale.type";
import { API } from "../../config/config";
import { PriceFor } from "../../types/service.type";

const { Option } = Select;

interface EditWholesaleProps {
  visible: boolean;
  onClose: () => void;
  wholesale: WholesaleProduct;
  onUpdateWholesale?: (wholesaleId: string, wholesaleData: any) => void;
}

const EditWholesale: React.FC<EditWholesaleProps> = ({
  visible,
  onClose,
  wholesale,
  onUpdateWholesale,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [photo, setPhoto] = useState<File | null>(null);
  const [photoPreview, setPhotoPreview] = useState<string | null>(null);
  const [priceFor, setPriceFor] = useState<string>(wholesale.priceFor || "");

  const uploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    form.setFieldsValue({
      title: wholesale.title,
      description: wholesale.description,
      price: wholesale.price,
      priceFor: wholesale.priceFor,
    });
    if (wholesale.photoUri) {
      setPhotoPreview(`${API}/${wholesale.photoUri}`);
    }
  }, [wholesale, form]);

  useEffect(() => {
    return () => {
      if (photoPreview && !photo) {
        URL.revokeObjectURL(photoPreview);
      }
    };
  }, [photo, photoPreview]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setPhoto(file);
    if (file) {
      setPhotoPreview(URL.createObjectURL(file));
    }
  };

  const handleDeletePhoto = () => {
    setPhoto(null);
    setPhotoPreview(null);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("price", values.price);
    formData.append("priceFor", values.priceFor);

    if (photo) {
      formData.append("photo", photo);
    }

    try {
      if (onUpdateWholesale) {
        await onUpdateWholesale(wholesale._id, formData);
      }
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Редактировать оптовый продукт"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          title: wholesale.title,
          description: wholesale.description,
          price: wholesale.price,
          priceFor: wholesale.priceFor,
        }}
      >
        <Form.Item
          name="title"
          label="Название"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите название продукта!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Описание"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите описание продукта!",
            },
            {
              max: 400,
              message: "Описание не может содержать более 400 символов",
            },
            {
              min: 30,
              message: "Описание не может содержать менее 30 символов",
            },
          ]}
        >
          <Input.TextArea rows={4} minLength={30} maxLength={400} showCount />
        </Form.Item>
        <Form.Item
          name="price"
          label="Цена"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите цену продукта!",
            },
          ]}
        >
          <InputNumber min={0} suffix="₽" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="priceFor"
          label="Цена за"
          rules={[{ required: true, message: "Пожалуйста, укажите цену за!" }]}
        >
          <Select value={priceFor} onChange={setPriceFor}>
            {Object.values(PriceFor).map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="photo" label="Фото">
          <div>
            <input
              ref={uploadRef}
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <Button
              icon={<UploadOutlined />}
              onClick={() => uploadRef.current?.click()}
            >
              Загрузить фотографию
            </Button>
            {(photo || photoPreview) && (
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Image
                    src={photoPreview || `${API}/${wholesale.photoUri}`}
                    alt="photo-preview"
                    style={{
                      width: 120,
                      height: 80,
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={handleDeletePhoto}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      zIndex: 1,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            loading={loading}
            htmlType="submit"
            style={{ marginRight: "8px" }}
          >
            Сохранить
          </Button>
          <Button onClick={onClose}>Отмена</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditWholesale;

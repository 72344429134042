import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import useGetRequest from "../../hooks/useGetRequest";
import { API } from "../../config/config";
import { Transaction, TransactionType } from "../../types/transaction.type";
import { Table, Typography, Space, Tag, Spin, Divider } from "antd";

const { Title, Text } = Typography;

export default function Finance() {
  const { authState } = useAuth();
  const { data, loading } = useGetRequest(
    `${API}/seller/transactions?userType=${authState?.userType}`
  );
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [balanceAmount, setBalanceAmount] = useState<number>();

  useEffect(() => {
    if (data) {
      setTransactions(data.balance.transactions);
      setBalanceAmount(data.balance.amount);
    }
  }, [data]);

  const columns = [
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
      render: (text: string) => text || "Без описания",
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      filters: [
        { text: "Зачисление", value: TransactionType.CREDIT },
        { text: "Списание", value: TransactionType.DEBIT },
      ],
      onFilter: (value: string | number | boolean, record: Transaction) =>
        record.type === value,
      render: (type: TransactionType) => (
        <Tag color={type === TransactionType.CREDIT ? "green" : "red"}>
          {type === TransactionType.CREDIT ? "Зачисление" : "Списание"}
        </Tag>
      ),
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      key: "amount",
      sorter: (a: Transaction, b: Transaction) => a.amount - b.amount,
      render: (amount: number, record: Transaction) => (
        <Text
          strong
          style={{
            color: record.type === TransactionType.CREDIT ? "green" : "red",
          }}
        >
          {record.type === TransactionType.CREDIT ? `+${amount}` : `-${amount}`}{" "}
          ₽
        </Text>
      ),
    },
  ];

  if (loading)
    return (
      <Spin size="large" style={{ display: "block", margin: "100px auto" }} />
    );

  return (
    <div style={{ padding: 24 }}>
      <Title level={2}>Финансы</Title>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div>
          <Text strong style={{ fontSize: 18 }}>
            Текущий баланс:{" "}
          </Text>
          <Text style={{ fontSize: 18, color: "green" }}>
            {balanceAmount !== undefined
              ? `${balanceAmount.toFixed(2)} ₽`
              : "Неизвестно"}
          </Text>
        </div>

        <Divider />

        <Table
          dataSource={transactions}
          columns={columns as any}
          rowKey={(record) =>
            `${record.amount}-${record.type}-${Math.random()}`
          }
          pagination={{ pageSize: 30 }}
        />
      </Space>
    </div>
  );
}

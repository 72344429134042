export type Point = {
  latitude: number;
  longitude: number;
  city: string;
  apartment: string;
  floor: string;
  street: string;
};

export enum OrderStatus {
  AwaitingPayment = "Ожидание оплаты",
  AwaitingCourier = "Ожидание курьера",
  CourierPickedUp = "Курьер забрал",
  InTransit = "В пути",
  Delivered = "Доставлено",
  Cancelled = "Отменено",
  UnderReview = "На рассмотрении",
}

export interface Order {
  clientId: string;
  deliveryAmount: number;
  price: number;
  total: number;
  points: Point[];
  weight: number;
  distance: number;
  clientPhone: string;
  clientPoint: Point;
  clientName: string;
  comment?: string;
  courierId: string;
  courierPhone?: string;
  status: OrderStatus;
  trackingId: string;
  sellerProducts: OrderSellerProduct[];
  createdAt: Date;
  deliveryDate: Date;
}

export interface OrderSellerProduct {
  seller: string;
  name: string;
  photoUri: string;
  deliveryAmount: number;
  products: OrderProduct[];
}

export interface OrderProduct {
  _id: string;
  title: string;
  photoUri: string;
  selectedOption: string;
  selectedOptionIndex: Number;
  weight: number;
  owner: string;
  ownerName: string;
  ownerPhoto: string;
  quantity: number;
  price: number;
}

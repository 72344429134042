import React, { useState } from "react";
import { Avatar, Typography, Row, Col, Tag, Button, Card, Space } from "antd";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import { API } from "../../config/config";
import {
  UserOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  TagOutlined,
  SyncOutlined,
  MailOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Address } from "../../types/address.type";
import { categories } from "../../constants/categories.constant";
import EditProfile from "../../components/EditProfile/editProfile.component";
import Workers from "../../components/Workers/workers.component";
import { ISchedule } from "../../types/schedule.type";
import Branches from "../../components/Branches/branches.component";
import Couirers from "../../components/Couriers/couriers.component";
import FreeDelivery from "../../components/FreeDelivery/freeDelivery.component";

const { Title, Text } = Typography;

export default function Profile() {
  const { authState } = useAuth();
  const [visible, setVisible] = useState(false);
  const isWorker = authState?.userType === "worker";

  const getCategoryName = (categoryId: string | null) => {
    const category = categories.find((cat) => cat.id.toString() === categoryId);
    return category ? category.name : "Категория не указана";
  };

  const renderPermissionTag = (permission: boolean | null, text: string) => (
    <Tag
      color={permission ? "green" : "red"}
      icon={permission ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
    >
      {text}
    </Tag>
  );

  const renderProfileSection = (
    address?: Address | null,
    status?: "active" | "inactive" | null,
    categoryId?: string | null,
    schedule?: ISchedule | null,
    email?: string | null,
    sellerPhone?: string | null
  ) => (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      {sellerPhone && (
        <div>
          <PhoneOutlined style={{ marginRight: 8 }} />
          <Text>{sellerPhone} (Номер магазина)</Text>
        </div>
      )}
      {email && (
        <div>
          <MailOutlined style={{ marginRight: 8 }} />
          <Text>{email}</Text>
        </div>
      )}
      <div>
        <EnvironmentOutlined style={{ marginRight: 8 }} />
        <Text>
          {address
            ? `${address.city}, ${address.street}, дом ${address.house} ,вход ${address.entrance} кв.${address.apartment}, ${address.floor} этаж`
            : "Адрес не указан"}
        </Text>
      </div>
      <div>
        <SyncOutlined style={{ marginRight: 8 }} />
        <Tag color={status === "active" ? "green" : "red"}>
          {status || "Статус не указан"}
        </Tag>
      </div>
      {categoryId ? (
        <div>
          <TagOutlined style={{ marginRight: 8 }} />
          <Text>{getCategoryName(categoryId)}</Text>
        </div>
      ) : (
        <div>
          <TagOutlined style={{ marginRight: 8 }} />
          <Text>Категория не указана</Text>
        </div>
      )}
      <div>
        <CalendarOutlined style={{ marginRight: 8 }} />
        {schedule && schedule.length > 0 ? (
          schedule.map((item, index) => (
            <div key={index}>
              <Text>
                <strong>{item.dayOfWeek}:</strong>{" "}
                {item.is24Hours
                  ? "Круглосуточно"
                  : item.isDayOff
                  ? "Выходной день"
                  : item.openTime && item.closeTime
                  ? `${item.openTime} - ${item.closeTime}`
                  : "Время работы не указано"}
              </Text>
            </div>
          ))
        ) : (
          <Text>График не указан</Text>
        )}
      </div>
    </Space>
  );

  return (
    <div style={{ padding: 24 }}>
      <Title level={2} style={{ marginBottom: 32 }}>
        Профиль
      </Title>

      <Row gutter={24}>
        <Col span={8}>
          <Card
            bordered={false}
            style={{
              borderRadius: 12,
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            <Avatar
              src={
                authState?.photoUri
                  ? `${API}/${authState?.photoUri}`
                  : undefined
              }
              size={100}
              style={{ backgroundColor: "#1890ff", marginBottom: 16 }}
              icon={<UserOutlined />}
            />
            <Title level={4}>{authState?.name}</Title>
            <Text type="secondary">{authState?.sellerName}</Text>
            {authState?.phone && (
              <div style={{ marginTop: 16 }}>
                <PhoneOutlined style={{ marginRight: 8 }} />
                <Text>{authState?.phone}</Text>
              </div>
            )}
          </Card>
        </Col>

        <Col span={16}>
          <Row gutter={24}>
            <Col span={12}>
              <Card
                bordered={false}
                style={{
                  borderRadius: 12,
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                }}
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>Информация</span>
                    {!isWorker && (
                      <Button
                        onClick={() => setVisible(true)}
                        type="primary"
                        style={{
                          borderRadius: 8,
                        }}
                      >
                        Редактировать
                      </Button>
                    )}
                  </div>
                }
              >
                {renderProfileSection(
                  authState?.address,
                  authState?.status,
                  authState?.category,
                  authState?.schedule,
                  authState?.email,
                  authState?.sellerPhone
                )}
              </Card>
            </Col>

            {isWorker && (
              <Col span={12}>
                <Card
                  bordered={false}
                  style={{
                    borderRadius: 12,
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  }}
                  title="Права доступа"
                >
                  <Space direction="vertical" size="middle">
                    {renderPermissionTag(
                      authState?.canAddProducts ?? null,
                      "Добавление товаров"
                    )}
                    {renderPermissionTag(
                      authState?.canModifyProducts ?? null,
                      "Изменение товаров"
                    )}
                    {renderPermissionTag(
                      authState?.canDeleteProducts ?? null,
                      "Удаление товаров"
                    )}
                  </Space>
                </Card>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <EditProfile visible={visible} onClose={() => setVisible(false)} />
      <FreeDelivery />
      {!isWorker && <Workers />}
      {!isWorker && <Couirers />}
      {!isWorker && <Branches />}
    </div>
  );
}

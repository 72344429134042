import React, { useState, useRef } from "react";
import { Modal, Form, Input, InputNumber, Button, Select, Image } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { PriceFor } from "../../types/service.type";

interface NewWholesaleProps {
  visible: boolean;
  onClose: () => void;
  onCreateWholesale: (productData: FormData) => void;
}

const NewWholesale: React.FC<NewWholesaleProps> = ({
  visible,
  onClose,
  onCreateWholesale,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [photo, setPhoto] = useState<File | null>(null);
  const [priceFor, setPriceFor] = useState<string>("");

  const uploadRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setPhoto(file);
  };

  const handleDeletePhoto = () => {
    setPhoto(null);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("price", values.price);
    formData.append("priceFor", priceFor);

    if (photo) formData.append("photo", photo);

    try {
      await onCreateWholesale(formData);
      form.resetFields();
      setPhoto(null);
      setPriceFor("");
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Создать оптовый продукт"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="title"
          label="Название"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите название продукта!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Описание"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите описание продукта!",
            },
            {
              max: 400,
              message: "Описание не может содержать более 400 символов",
            },
            {
              min: 30,
              message: "Описание не может содержать менее 30 символов",
            },
          ]}
        >
          <Input.TextArea rows={4} minLength={30} maxLength={400} showCount />
        </Form.Item>
        <Form.Item
          name="price"
          label="Цена"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите стоимость продукта!",
            },
          ]}
        >
          <InputNumber min={0} suffix="₽" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="priceFor"
          label="Цена за"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите, за что указывается цена!",
            },
          ]}
        >
          <Select
            placeholder="Выберите"
            value={priceFor}
            onChange={setPriceFor}
          >
            {Object.values(PriceFor).map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="photo"
          label="Фото"
          rules={[
            {
              required: true,
              message: "Пожалуйста, добавьте фото!",
            },
          ]}
        >
          <div>
            <input
              ref={uploadRef}
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <Button
              icon={<UploadOutlined />}
              onClick={() => uploadRef.current?.click()}
            >
              Загрузить фотографию
            </Button>
            {photo && (
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Image
                    src={URL.createObjectURL(photo)}
                    alt="photo-preview"
                    style={{
                      width: 120,
                      height: 80,
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={handleDeletePhoto}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      zIndex: 1,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            loading={loading}
            htmlType="submit"
            style={{ marginRight: "8px" }}
          >
            Сохранить
          </Button>
          <Button onClick={onClose}>Отмена</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewWholesale;
